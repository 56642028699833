import React, {useState} from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Dialog, Button, Image } from '../../components/library'


function dialog() {

    const [sampleOpen, setSampleOpen] = useState(false)
    const [sampleOpenTwo, setSampleOpenTwo] = useState(false)
    const [sampleOpenThree, setSampleOpenThree] = useState(false)

    return (
        <LibraryWrapper>
            <Button onClick={(e) => setSampleOpen(true)}>Open Sample Dialog</Button>
            <hr />
            <Button onClick={(e) => setSampleOpenTwo(true)}>Open Sample Dialog No Action</Button>
            <hr />
            <Button onClick={(e) => setSampleOpenThree(true)}>Open Sample Dialog Locked</Button>
            <Dialog
                id="sample-dialog"
                open={sampleOpen}
                title="Sample Dialog"
                description="Press and hold the wearable button for 3s to connect. Make sure the wearable is nearby."
                actionClick={(e) => console.log('DIALOG SUBMIT', e)}
                handleClose={(e) => setSampleOpen(false)}
                actionLabel="Sure thing!"
            >
               <Image src={'/batteries.png'} width="70%" />
            </Dialog>

            <Dialog
                id="sample-dialog"
                open={sampleOpenTwo}
                title="Sample Dialog No Action"
                description="Waiting for something..."
                handleClose={(e) => setSampleOpenTwo(false)}
            >
               <Image src={'/batteries.png'} width="70%" />
            </Dialog>

            <Dialog
                id="sample-dialog-lock"
                open={sampleOpenThree}
                title="Sample Dialog Locked"
                description="Can't close this..."
            >
               <Image src={'/batteries.png'} width="70%" />
            </Dialog>

        </LibraryWrapper>
    )
}
export default dialog
